
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import ExcelJS from "exceljs";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "roadmap",
    components: {},
    data: function () {
        return {
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            roadmaps: [],
            user: {} as any,
            categories: [],
            loading: true,
        };
    },
    methods: {
        downloadRoadmap() {
            if (this.roadmaps && this.roadmaps.length > 0) {
                let suitableJson = this.roadmaps.map((action: any) => {
                    let status = "A faire";
                    if (action.status === "DONE") {
                        status = "Terminée";
                    } else if (action.status === "INPR") {
                        status = "En cours";
                    }
                    return {
                        Thématique: action.category.label,
                        Action: action.label,
                        Collaborateurs: action.collaborators,
                        Échéance: action.deadline,
                        Status: status,
                        Commentaires: action.commentary,
                    };
                });

                // Create a new workbook and worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet("plan_actions_utilisateur");

                // Define columns with headers and keys
                worksheet.columns = [
                    { header: "Thématique", key: "Thématique", width: 20 },
                    { header: "Action", key: "Action", width: 30 },
                    { header: "Collaborateurs", key: "Collaborateurs", width: 25 },
                    { header: "Échéance", key: "Échéance", width: 15 },
                    { header: "Status", key: "Status", width: 15 },
                    { header: "Commentaires", key: "Commentaires", width: 30 },
                ];

                // Add the data rows
                suitableJson.forEach((row) => {
                    worksheet.addRow(row);
                });

                // Apply style to the header row
                worksheet.getRow(1).eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ADD8E6' }, // Light blue background color
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                // Export the file
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "plan_actions_utilisateur.xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
            }
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        getUserRoadmaps() {
            roadMapApi
                .getUserRoadmaps(this.$route.params.user_id)
                .then((response) => {
                    this.roadmaps = response.data["roadmaps"];
                    this.user = response.data["user"];
                    this.loading = false;
                    setCurrentPageBreadcrumbs(this.t("pages.roadmap.userRoadmaps") + this.user.userprofile.lastname + " " + this.user.userprofile.firstname, [
                        {
                            link: "/roadmap/",
                            label: this.t("pages.roadmap.title"),
                        },
                        {
                            link: "/roadmap/related-roadmap/",
                            label: this.t("pages.roadmap.availableRoadmaps"),
                        },
                    ]);
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.title"), []);

        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary,
                },
            });
        }

        return { t, editRoadmap };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.userRoadmaps") + this.user.userprofile.lastname + " " + this.user.userprofile.firstname, [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
                {
                    link: "/roadmap/related-roadmap/",
                    label: this.t("pages.roadmap.availableRoadmaps"),
                },
            ]);
        },
    },
    created() {
        this.getUserRoadmaps();
    },
});
